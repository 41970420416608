import { Box } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { FC } from "react";

interface snackbar {
  open: boolean;
  message: string | "";
  setOpen: (value: any) => void;
  type?: any;
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CustomSnackBar: FC<snackbar> = ({
  setOpen,
  open,
  type,
  message,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        key={"Top-Right"}
        autoHideDuration={5000}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
