import gql from 'graphql-tag';

export const COIN_OPTIONS = gql`
  query Coin__Options {
    coinOptions {
      id
      value
      label
      currency
    }
  }
`;
