import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  createMuiTheme,
  InputAdornment,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CustomBackdrop } from '../../../common/BackDrop/BackDrop';
import CustomAutoComplete from '../../../common/CustomAutocomplette/CustomAutoComplete';
import { CustomButton } from '../../../common/CustomButton/CustomButton';
import { CustomDialog } from '../../../common/customDialog/CustomDialog';
import { CustomRadioButton } from '../../../common/customRadio/CustomRadio';
import { CustomSnackBar } from '../../../common/CustomSnackBar/CustomSnackBar';
import { CustomTextField } from '../../../common/customTextField/CustomTextField';
import { AMOUNT_NOT_AVAILABLE, USER_UNKNOWN } from '../../../constant';
import {
  DO_GET_BALANCE,
  DO_GET_CURRENCY_BALANCE,
} from '../../../graphql/Balances/queries';
import {
  GET_BALANCE,
  GET_BALANCE_balances,
} from '../../../graphql/Balances/__generated__/GET_BALANCE';
import {
  GET_CURRENCY_BALANCE,
  GET_CURRENCY_BALANCEVariables,
} from '../../../graphql/Balances/__generated__/GET_CURRENCY_BALANCE';
import { DO_MAKE_TRANSACTION } from '../../../graphql/transactions/mutation';
import {
  MAKE_TRANSACTION,
  MAKE_TRANSACTIONVariables,
} from '../../../graphql/transactions/__generated__/MAKE_TRANSACTION';
import { AuthContext } from '../../../provider/Authprovider';
import {
  TransactionInput,
  TransactionMethod,
} from '../../../types/graphql-global-types';
import { useStyles } from './style';
import { UseTransfert } from './utils/useTransfert';
import { BalanceContext } from '../../../provider/Balanceprovider';
import {
  capitalizeFirstLetter,
  formatAmountLetter,
  formatUsernameWithCountryCode,
} from '../../../utils/helpers';
import { getUser } from '../../../provider/localesStorage';
import Cart from '../acceuil/Balance/Cart';

const BodyTransfert = () => {
  const { listUsers, options, userLoading, errorMessage, errorUser } =
    UseTransfert();
  const { id }: any = useParams();
  const { onShowSnackBar } = useContext(AuthContext);
  const { balance } = useContext(BalanceContext);
  const classes = useStyles();
  const history = useHistory();
  const user = getUser();
  const [open, setOpen] = useState<boolean>(false);
  const [transfertStatut, setTransfertStatut] = useState<boolean>(true);
  const [input, setInput] = useState<TransactionInput>({
    amount: 0,
    currencyId: id,
    transactionMethod: TransactionMethod.AUTRES,
    userRecipientId: '',
  });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [typeSnack, setTypeSnak] = useState('');
  const [message, setMessage] = useState('');
  const [valueAuto, setvalueAuto] = useState<any>();
  const [nameUnity, setUnityName] = useState('Coins');

  const [doMakeTransaction, { loading: loadingTransaction }] = useMutation<
    MAKE_TRANSACTION,
    MAKE_TRANSACTIONVariables
  >(DO_MAKE_TRANSACTION, {
    onCompleted: (data) => {
      const transactionHash = data?.makeTransaction?.transaction_hash ?? '';
      history.push('/');
      onShowSnackBar(
        `Votre transfert a été réalisé avec succès sous le Nº ${transactionHash}`,
        'Success',
        true,
      );
    },
    onError: (errors) => {
      if (errors.networkError) {
        isFailed('Veuillez vérifier le réseau');
      }
      errors.graphQLErrors.map((error) => {
        isFailed(error.message);
        return error;
      });
    },
    update: (cache, { data }) => {
      if (data && data.makeTransaction) {
        const request = cache.readQuery<GET_BALANCE, GET_BALANCE_balances>({
          query: DO_GET_BALANCE,
        });
        if (request && request.balances) {
          cache.writeQuery<GET_BALANCE, GET_BALANCE_balances>({
            query: DO_GET_BALANCE,

            data: { balances: { ...request.balances } },
          });
        }
      }
    },
  });

  const { data } = useQuery<
    GET_CURRENCY_BALANCE,
    GET_CURRENCY_BALANCEVariables
  >(DO_GET_CURRENCY_BALANCE, {
    variables: { currencyId: parseInt(id, 10) },
  });
  const currencyValue = parseFloat(data?.currencyBalace?.value || '');
  const isAllowedTransfert: boolean =
    input.amount <= currencyValue ? true : false;
  const isFailed = (message: string) => {
    setOpenSnack(true);
    setTypeSnak('error');
    setMessage(message);
  };

  const balanceValue = useMemo<GET_BALANCE_balances | null>(
    () => balance?.filter((i) => i?.currency?.toString() === id)[0] ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balance],
  );
  const valueOptionPP = [
    {
      value: TransactionMethod.FOURNITURE_PRESTATION,
      label: 'Fourniture - Prestation',
    },
    { value: TransactionMethod.IMPOT_TAXE, label: 'Impôt - Taxe' },
    {
      value: TransactionMethod.SALAIRE_REMUNERATION,
      label: 'Salaire - Rénumération',
    },
    { value: TransactionMethod.DEBIT, label: "(débit) Compte d'associés" , asterisk: 1},
    {
      value: TransactionMethod.HEBERGEMENT_PROFESSIONNEL,
      label: 'Hébergement professionnel',
    },
    { value: TransactionMethod.ASSURANCE, label: 'Assurance' },
    {
      value: TransactionMethod.DEBLOCAGE_PRET_AVANCE_REMBOURSABLE,
      label: 'Déblocage prêt - avance remboursable',
      asterisk: 2
    },
    {
      value: TransactionMethod.RETOUR_DES_FONDS_INVESTIS,
      label: 'Retour des fonds investis',
      asterisk: 2
    },
    { value: TransactionMethod.DON, label: 'Don' },
    {
      value: TransactionMethod.VERSMENT_DIVIDENDES,
      label: 'Versement dividendes',
      asterisk: 1
    },
    { value: TransactionMethod.FRAIS_FINANCIERE, label: 'Frais financiers' },
    {
      value: TransactionMethod.REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE,
      label: 'Remboursement prêt-avance remboursable',
    },
    {
      value: TransactionMethod.INVESTISSEMENT_FINANCIER,
      label: 'Investissement financier',
    },
    { value: TransactionMethod.AUTRES, label: 'Autres' },
  ];

  const valueOption = [
    {
      value: TransactionMethod.CONSOMMATION_PRESTATION,
      label: 'Consommation - Prestation',
    },
    { value: TransactionMethod.IMPOT_TAXE, label: 'Impôt - Taxe' },
    {
      value: TransactionMethod.SOLIDARITE_CONTRIBUTION,
      label: 'Solidarité et contribution',
    },
    { value: TransactionMethod.CREDIT, label: "(crédit)Compte d'associés" },
    {
      value: TransactionMethod.HEBERGEMENT_PRIVATIF,
      label: 'Hébergement privatif',
    },
    { value: TransactionMethod.ASSURANCE, label: 'Assurance' },
    {
      value: TransactionMethod.REMBOURSEMENT_PRET,
      label: 'Remboursement prêt',
    },
    {
      value: TransactionMethod.INVESTISSEMENT_FINANCIER,
      label: 'Investissement financier',
    },
    { value: TransactionMethod.DON_POURBOIR, label: 'Don-Pourboire' },
    { value: TransactionMethod.FRAIS_FINANCIERE, label: 'Frais financier' },
    { value: TransactionMethod.AUTRES, label: 'Autres' },
  ];

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
      secondary: {
        main: transfertStatut ? '#02C39A' : '#F35F83',
      },
    },
  });

  const isExist = (input: string) => {
    if (options && options.userOptions) {
      const theUser = options.userOptions.find(
        (element) => element && element.id === input,
      );
      if (theUser && theUser.id) {
        return true;
      }
    }
    return false;
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((prevstate) => ({ ...prevstate, [name]: value }));
  };

  const onCLickTransfert = async () => {
    if (input.amount <= 0) {
      isFailed('Montant invalide');
    } else if (input.userRecipientId === '') {
      isFailed('Veuillez ajouter un bénéficiaire');
    } else {
      const isUserRecipientIdExist = isExist(input.userRecipientId);
      isUserRecipientIdExist ? setOpen(true) : isFailed(USER_UNKNOWN);
    }
  };
  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      onCLickTransfert();
    }
  };

  const isOnOpen = () => {
    listUsers();
    if (errorUser) {
      isFailed(errorMessage);
    }
  };
  const onChangeAutoComplete = (_e: any, value: any) => {
    if (typeof value == 'string' && options && options.userOptions) {
      const newValue = options.userOptions.find(
        (item) => item && item.value === value.trim(),
      );
      if (newValue) {
        setInput((prevstate) => ({
          ...prevstate,
          userRecipientId: newValue.id ? newValue.id : '',
        }));
        setvalueAuto(newValue.value);
      } else {
        setInput((prevstate) => ({
          ...prevstate,
          userRecipientId: 'unknown',
        }));
        value && setvalueAuto(value.trim());
      }
    } else {
      setInput((prevstate) => ({ ...prevstate, userRecipientId: value.id }));
      setvalueAuto(value.value);
    }
  };

  useEffect(() => {
    switch (id) {
      case '1':
        setUnityName('Coins');
        break;
      case '2':
        setUnityName('CCoins');
        break;
      case '3':
        setUnityName('CCCoins');
        break;
      case '4':
        setUnityName('Euro');
        break;
      default:
        setUnityName('Coins');
        break;
    }
  }, [id]);

  useEffect(() => {
    if (input.amount > currencyValue) {
      isFailed(AMOUNT_NOT_AVAILABLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const nameUnitySIng =
    nameUnity === 'CCoins'
      ? 'CCoin'
      : nameUnity === 'Coins'
      ? 'Coin'
      : nameUnity === 'CCCoins'
      ? 'CCCoin'
      : nameUnity;

  const val = input.amount.toString().split('.').length;
  const montante = input.amount.toString().split('.');
  const amounte: number = input.amount.toString().includes('e-')
    ? 0
    : montante.length > 1
    ? Number(montante[0] + '.' + montante[1]?.slice(0, 3))
    : input.amount;

  const amountFixed = Number(amounte).toString().replace('.', ',');
  const amountNotFixed = input.amount.toString().includes('e-')
    ? '0'
    : val === 1
    ? Number(input.amount)
    : formatAmountLetter(amountFixed);
  const amountletters =
    input.amount < 2
      ? amountNotFixed + ' ' + nameUnitySIng
      : amountNotFixed + ' ' + nameUnity;

  const handleClickConfirm = async () => {
    if (input.amount < 0) {
      setTransfertStatut(false);
    } else if (!loadingTransaction) {
      setOpen(false);
      await doMakeTransaction({
        variables: {
          transactionInput: {
            amount: Number(amounte),
            currencyId: Number(input.currencyId),
            transactionMethod: input.transactionMethod,
            userRecipientId: input.userRecipientId,
          },
        },
      });
    }
  };

  return (
    <Box className={classes.transfertContainer}>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        singleActionButton={true}
        maxWidth="sm"
        onClick={handleClickConfirm}
        className={classes.confirmDialog}
      >
        <Typography variant={'h4'} className={classes.subTitre}>
          Confirmez-vous le transfert de {amountNotFixed}  {data?.currencyBalace?.code } à{' '}
          <strong style={{ fontWeight: 'bold' }}>
            {formatUsernameWithCountryCode(capitalizeFirstLetter(valueAuto))}
          </strong>{' '}
          ?
        </Typography>
      </CustomDialog>
      <Typography variant="h3" className={classes.mainTitre}>
        Transfert de valeurs
      </Typography>
      { user.legalEntity &&  <Box className={classes.boxLegend}>
          <Typography variant="h5" className={classes.legend}>
              <Typography component="span" className={classes.asterisk}>*</Typography> 
              <span style={{marginLeft: 15}}>&nbsp; Réservé aux sociétés (SA, Sarl, ...)</span> 
          </Typography>
          <Typography variant="h5" className={classes.legend}>
              <Typography component="span"  className={classes.asterisk}>**</Typography> 
              <span style={{marginLeft: 30}}>&nbsp;Réservé aux banques - fintechs - finance</span>
          </Typography>
      </Box>}
      <Box className={classes.fieldItem}>
        <Cart
          style={classes}
          devise={balanceValue?.currency || ''}
          value={balanceValue?.value || ''}
          balance={balanceValue}
        />
        <Typography className={classes.labeled}>
          Montant à transférer ?
        </Typography>
        <CustomTextField
          variant="outlined"
          name="amount"
          onKeyDown={(e: any) => onKeyPressed(e)}
          endAdornment={
            <InputAdornment position="end">{data?.currencyBalace?.code}</InputAdornment>
          }
          placeholder="0"
          onChange={onChange}
          className={classes.textField}
          type="number"
          inputProps={{
            min: 0,
            step: '0.001',
          }}
        />
      </Box>
      <Box className={classes.fieldItem}>
        <Typography className={classes.labeled}>
          Vers quel utilisateur ?
        </Typography>
        <CustomAutoComplete
          id={'id'}
          onOpen={isOnOpen}
          loading={userLoading}
          className={classes.textField}
          options={
            (options &&
              options.userOptions &&
              options.userOptions.length &&
              options.userOptions.filter((uo) => uo !== null)) ||
            []
          }
          inputValue={valueAuto}
          optionLabelKey="label"
          placeholder="Nom du bénéficiaire"
          name="userRecipientId"
          onChange={(e, value) => onChangeAutoComplete(e, value)}
        />
      </Box>
      {user?.legalEntity?.id ? (
        <Box className={classes.radioListItem}>
          <CustomRadioButton
            name="transactionMethod"
            titregroup="Types de transactions"
            arialabel="transaction"
            value={input.transactionMethod}
            onChange={onChange}
            row={true}
            valueOption={valueOptionPP}
            className={classes.styleRadioPP}
          />
        </Box>
      ) : (
        <Box className={classes.radioListItem}>
          <CustomRadioButton
            name="transactionMethod"
            titregroup="Types de transactions"
            arialabel="transaction"
            value={input.transactionMethod}
            onChange={onChange}
            row={true}
            valueOption={valueOption}
            className={classes.styleRadio}
          />
        </Box>
      )}

      <ThemeProvider theme={theme}>
        <CustomButton
          {...{
            nameBtn: 'TRANSFÉRER',
            color: 'primary',
            variant: 'contained',
            className: classes.btnconnect,
            onClick: onCLickTransfert,
            disabled: !isAllowedTransfert,
          }}
        />
      </ThemeProvider>
      <CustomSnackBar
        {...{
          open: openSnack,
          setOpen: setOpenSnack,
          message: message,
          type: typeSnack,
        }}
      />
      <CustomBackdrop {...{ open: loadingTransaction }} />
    </Box>
  );
};

export default BodyTransfert;
