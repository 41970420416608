import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// Style of the component
const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '30%',
    padding: '10px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    backgroundColor: '#FFFFFF',
    border: "3px solid #E9EAEC",
  },
}));


const ConversionPictogram: React.FC= ( ) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
    >
      <Typography variant="h6" style={{ color:  '#000000'}}>
        EU
      </Typography>
    </Box>
  );
};

export default ConversionPictogram;
