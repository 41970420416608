import gql from "graphql-tag";

export const OnBalanceUpdate = gql`
  subscription onBalanceUpdate($address: String) {
    onBalanceUpdate(address: $address) {
      balance {
        currency
        value
        code
        shortCode
        color
        isValidated
        shouldDead
      }
    }
  }
`;

export const OnEuCoinsBalanceUpdate = gql`
  subscription onEuCoinsBalanceUpdate($address: String) {
    onEuCoinsBalanceUpdate(address: $address) {
      value
    }
  }
`;
